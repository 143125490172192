/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import './button-reset.scss';

/**
 * A Button
 *
 * @version 0.0.1
 * @author [Jeremy Folds](https://github.com/jmfolds)
 */
const Button = ({ color, size, children, ...others }) => {
  const propsFinal = Object.assign(
    {
      type: 'button'
    },
    { color, 'data-size': size },
    { ...others }
  );
  return (
    <button className="btn btn-dark" {...propsFinal}>
      {children}
    </button>
  );
};
Button.propTypes = {
  size: PropTypes.oneOf(['sm', 'lg']),
  color: PropTypes.string,
  // eslint-disable-next-line
  children: PropTypes.any
};

Button.defaultProps = {
  size: 'sm',
  color: 'currentColor'
};

export default Button;
